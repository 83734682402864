import Vue from 'vue'
import Vuex from 'vuex'

import user from './module/user'
import app from './module/app'
import { getLastRouter } from '../libs/utils'
import { Message } from 'element-ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null,
    aid: 0,
    role: 0,
    openQuestion: {
      title: '',
      content: [],
      visible: false,
      answered: false,
    },
    idVerify: {
      code: '',
      verified: false,
      visible: false,
      odid: '',
    }
  },
  getters: {
    getUser: state => {
      return state.userInfo || {
        urid: localStorage.getItem('urid'),
        token: localStorage.getItem('token'),
      }
    },
    getRole: state => {
      return state.role
    }
  },
  mutations: {
    setAid(state, v) {
      state.aid = v
    },
    setRole(state, v) {
      state.role = v
    },
    setUser(state, v) {
      if (!v) {
        localStorage.setItem('urid', '')
        localStorage.setItem('token', '')
        state.userInfo = null
      } else {
        localStorage.setItem('urid', v.urid)
        localStorage.setItem('token', v.token)
        state.userInfo = {
          urid: v.urid,
          token: v.token
        }
      }
    },
    setOpenQuestion(state, v) {
      state.openQuestion = {
        ...v,
        visible: false,
        answered: v.answered,
        picked: [],
      }
    },
    setOpenQuestionPicked(state, index) {
      let idx = state.openQuestion.picked.indexOf(index)
      if (idx !== -1) {
        state.openQuestion.picked.splice(idx, 1)
      } else {
        if (state.openQuestion.picked.length >= 3) {
          Message.warning('最多可选 3 项')
        } else {
          state.openQuestion.picked.push(index)
        }
      }
      return true
    },
    setOpenQuestionVisiable(state, v) {
      state.openQuestion.visible = v
    },
    setOpenQuestionAnswered(state, v) {
      state.openQuestion.answered = v
    },
    setIDVerified(state, v) {
      state.idVerify.verified = v
    },
    setIDVerifyVisiable(state, v) {
      state.idVerify.visible = v
    },
    setIDVerifyCode(state, v) {
      state.idVerify.code = v
    },
    setIDVerifyOdid(state, v) {
      state.idVerify.odid = v
    },
  },
  actions: {
  },
  modules: {
  }
})
