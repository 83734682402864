<template>
  <div class="home">
    <el-dialog :visible.sync="show" width="90%" :show-close="false">
      <div slot="title">
        <div>规则说明</div>
        <div>Game Rules</div>
      </div>
      <div v-html="detail.description" class="roll"></div>
      <div class="mvbutton btn" @click="goques">已确认知晓活动规则<br />Rule Confirmed</div>
    </el-dialog>
  </div>
</template>

<script>

import api from '@/api/api'
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: 'Home',
  data() {
    return {
      detail: {},
      show: false,
      questionLink: {}
    }
  },
  computed: {
    ...mapState(["aid"])
  },
  methods: {
    ...mapMutations(["setAid"]),
    ...mapGetters(["getUser"]),
    goques(){
      this.$router.push(this.questionLink)
    }
  },
  mounted () {
    let user = this.getUser()
    if (user.urid) {
      this.$router.push({
        path: '/main'
      })
    } else {
      api.detail(932).then(r => {
        this.detail = r.data.data
        this.show = true
        this.questionLink = {
          name: 'login',
          query: {
            aid: this.detail.cats[0]
          }
        }
        this.setAid(this.detail.cats[0])
      })
    }
  }
}
</script>

<style scoped>
  .home .roll{
    height: 350px;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 13px;
    color: #333333;
    font-weight: 400;
    text-align: left;
  }
  .home .btn{
    width: 150px;
    height: 58.5px;
    font-size: 12px;
    margin: auto;
  }
</style>
