<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import router from "@/router"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
  methods: {
    ...mapMutations(["setUser"]),
    logout() {
      this.setUser(null)
      router.push({
        name: "login",
      });
    },
  }
}
</script>


<style>
@font-face{
  font-family: 'DIN';
  src:url('https://oss.mcloud.moveclub.cn/mpms/20210604/1622783853-60b9b76d872ae.otf');
}
html {
  margin: 0;
  height: 100%;
}
body{
  /*background-color: rgba(214, 201, 172, 1);*/
  background: url("https://oss.mcloud.moveclub.cn/mpms/20210604/1622783711-60b9b6df3dc9d.png");
  background-size: cover;
  margin: 0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif,'DIN';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.mvbutton{
  background-color: rgba(54, 131, 116, 1);
  border-radius: 0 3px 0 3px;
  box-shadow: 5px 5px 5px rgb(36 100 88);
  font-size: 14px;
  font-weight: 400;
  height: 73px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
