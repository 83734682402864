import request from '@/api/request'

export default {
  sendCode(mobile) {
    return request.get('customize/sports-museum/send-code', {
      params: {mobile}
    })
  },
  verifyCode(mobile, smscode) {
    return request.get('customize/sports-museum/verify-code', {
      params: {mobile, smscode}
    })
  },
  userInfo() {
    return request.get('customize/sports-museum/userinfo')
  },
  detail(acid) {
    return request.get('customize/sports-museum/detail', {
      params: {acid}
    })
  },
  question(aid) {
    return request.get('mwap/questlist', {
      params: {aid}
    })
  },
  getWinList(data) {
    return request.post('customize/sports-museum/winlist', data)
  },
  getJackorder(data) {
    return request.post('customize/sports-museum/jack-order-detail', data)
  },
  delQuest() {
    return request.post('customize/sports-museum/cancel-quest')
  },

  callList(acid, transferstate, search) {
    return request.post('customize/sports-museum/call-number', {
      ...(search || {}),
      acid,
      transferstate
    })
  },

  printList(search) {
    return request.post('customize/sports-museum/print-list', {
      ...(search || {}),
    })
  },

  Healthy(search) {
    return request.post('customize/sports-museum/healthy', {
      ...(search || {}),
    })
  },

  checkList(acid, transferstate, search) {
    return request.post('customize/sports-museum/check-list', {
      ...(search || {}),
      transferstate
    })
  },

  callNumber(acid, id, op) {
    return request.post('customize/sports-museum/pass-number', {
      acid,
      id,
      op
    })
  },

  screen() {
    return request.post('customize/sports-museum/screen')
  },

  getItems(acid) {
    return request.post('customize/sports-museum/get-items', {
      acid,
    })
  },

  waitingNumber(acid, transferstate) {
    return request.post('customize/sports-museum/waiting-number', {
      acid,
      transferstate
    })
  },
  
  printDetail(odid, isforce) {
    return request.post('customize/sports-museum/print-detail', {
      odid,
      isforce
    })
  },
  
  checkJoin(data) {
    return request.get(`venue/reserve/check-join?value=${data.code}&field=idcard&times=${data.times}`, {
    })
  },
	
	getGuardState(){
		return request.post('venue/stadium/door?stid=4')
	},
	getDashboard(){
		return request.post('venue/stadium/statistics?stid=4')
	}
}
