import axios from 'axios'
import store from '@/store/index'
import router from "@/router"

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  timeout: 5000,
  headers: {
    'Accept': 'application/json',
  },
});

instance.interceptors.request.use(config => {
  let data = {}
  data.gid = process.env.VUE_APP_GID
  data.app = process.env.VUE_APP_APP
  let userinfo = store.getters.getUser
  if (userinfo) {
    data.urid = userinfo.urid
    data.token = userinfo.token
  }
  if (config.method === "get") {
    config.params = Object.assign(data, config.params || {})
  } else {
    config.data = Object.assign(data, config.data || {})
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (r) {
  if (r.data.status == 201) {
    if (r.data.message == '错误用户') {
      localStorage.removeItem('urid')
      router.push({
        'name': 'login'
      })
    }
  }
  return r
}, function (error) {
  return Promise.reject(error);
});

export default instance
