import HttpRequest from "./axios";
import config from "../config";
const baseUrl =
  process.env.NODE_ENV === "development"
    ? config.baseUrl.dev
    : config.baseUrl.pro;
const baseUrl2 =
  process.env.NODE_ENV === "development"
    ? config.baseUrl2.dev
    : config.baseUrl2.pro;

const baseUrl3 = config.baseUrl3;

const baseUrl1 = "https://mcloudbkapi.moveclub.cn/";
const host = "http://mpmsapi.51ydb.cn/";
// const host = "http://192.168.1.58:8080/";
const axios1 = new HttpRequest(baseUrl1);
const axios = new HttpRequest(baseUrl);
const api = new HttpRequest(host);
const axios2 = new HttpRequest(baseUrl2);
const axios3 = new HttpRequest(baseUrl3);

export const getMatchList = params => {
  return axios.request({
    url: "match/index",
    method: "get",
    params
  });
};

export const matchDetail = params => {
  return axios.request({
    url: "match/detail",
    method: "get",
    params
  });
};

export const getRegisterTypeList = params => {
  return axios.request({
    url: "match/registertypelist",
    method: "get",
    params
  });
};

export const prePayorder = data => {
  return axios.request({
    url: "pay/registerprepayorder",
    method: "post",
    data
  });
};

export const matchinfo = data => {
  //拿到报名组
  return axios.request({
    url: "matchregister/matchinfo",
    method: "post",
    data
  });
};

export const mymemberlist = data => {
  return axios.request({
    url: "matchregister/mymemberlist",
    method: "post",
    data
  });
};

export const typeattrtmpls = data => {
  return axios.request({
    url: "matchregister/typeattrtmpls",
    method: "post",
    data
  });
};

export const submitteampremembers = data => {
  return axios.request({
    url: "matchregister/submitteampremembers",
    method: "post",
    data
  });
};

export const userinfolist = data => {
  return axios.request({
    url: "user/userinfo",
    method: "post",
    data
  });
};

export const checkspeccode = data => {
  //测试不能用
  return axios.request({
    url: "pay/checkspeccode",
    method: "post",
    data
  });
};

export const submitpremembers = data => {
  return axios.request({
    url: "matchregister/submitpremembers",
    method: "post",
    data
  });
};

export const formsave = data => {
  return axios.request({
    url: "user/formsave",
    method: "post",
    data
  });
};

export const submitmemberinfos = data => {
  return axios.request({
    url: "matchregister/submitmemberinfos",
    method: "post",
    data
  });
};

export const registerpayorder = data => {
  return axios.request({
    url: "pay/registerpayorder",
    method: "post",
    data
  });
};

export const groupattrtmpls = data => {
  return axios.request({
    url: "matchregister/groupattrtmpls",
    method: "post",
    data
  });
};

export const editregistergroup = data => {
  return axios.request({
    url: "matchregister/editregistergroup",
    method: "post",
    data
  });
};

export const queryregisterpayorder = data => {
  return axios.request({
    url: "pay/queryregisterpayorder",
    method: "post",
    data
  });
};

export const groupinfo = data => {
  return axios.request({
    url: "matchregister/groupinfo",
    method: "post",
    data
  });
};

export const prememberlist = data => {
  return axios.request({
    url: "matchregister/prememberlist",
    method: "post",
    data
  });
};

export const deletemember = data => {
  return axios.request({
    url: "matchregister/deletemember",
    method: "post",
    data
  });
};

export const shareinfos = data => {
  return axios.request({
    url: "api/shareinfos",
    method: "post",
    data
  });
};

export const orderdetail = data => {
  return axios.request({
    url: "matchregister/orderdetail",
    method: "post",
    data
  });
};

export const mymatchindex = data => {
  return axios.request({
    url: "matchregister/mymatchindex",
    method: "post",
    data
  });
};

export const delpremember = data => {
  return axios.request({
    url: "matchregister/delpremember",
    method: "post",
    data
  });
};

export const recommend = data => {
  return axios.request({
    url: "match/recommend",
    method: "post",
    data
  });
};

export const search = data => {
  return axios.request({
    url: "match/search",
    method: "post",
    data
  });
};

export const wxLoginWap = data => {
  return axios.request({
    url: "user/login-wx-wap",
    method: "post",
    data
  });
};

export const myfavors = data => {
  return axios.request({
    url: "user/myfavors",
    method: "post",
    data
  });
};

export const likematch = data => {
  return axios.request({
    url: "user/likematch",
    method: "post",
    data
  });
};

export const addmember = data => {
  return axios.request({
    url: "matchregister/addmember",
    method: "post",
    data
  });
};

export const registerverify = data => {
  return axios.request({
    url: "sms/registerverify",
    method: "post",
    data
  });
};

export const editregisteruser = data => {
  return axios.request({
    url: "matchregister/editregisteruser",
    method: "post",
    data
  });
};

export const findgroup = data => {
  return axios.request({
    url: "match/findgroup",
    method: "post",
    data
  });
};

export const mpjsconfig = data => {
  return axios.request({
    url: "api/mpjsconfig",
    method: "post",
    data
  });
};

export const info = data => {
  return axios.request({
    url: "enterprise/info",
    method: "post",
    data
  });
};

export const registerinfo = data => {
  return axios.request({
    url: "matchregister/registerinfo",
    method: "post",
    data
  });
};

export const getfunctions = data => {
  return axios1.request({
    url: "match-func-mp/functions",
    method: "post",
    data
  });
};

export const appLaunch = () => {
  return axios.request({
    url: "api/app-launch",
    method: "post"
  });
};

export const  albumInfo = (data) => {
    return axios.request({
        url:'api/shareinfos',
        method:"post",
        data
    });
}


export const getCheckinConfig = data => {
  return axios1.request({
    url: "enroll-checkin-config/view",
    method: "post",
    data
  });
};

export const searchCheckin = data => {
  return axios1.request({
    url: "enroll-checkin-config/search",
    method: "post",
    data
  });
};

export const getCheckinInfo = data => {
  let url = "enroll-checkin-config/get-enroll-info";
  return axios1.request({
    url,
    method: "post",
    data
  });
};

export const getBanners = data => {
  let url =
    "banner/get-banners?position=" +
    data.position +
    "&positionid=" +
    data.positionid;
  return axios1.request({
    url,
    method: "get",
    data
  });
};

export const cerateCert = data => {
  let url = "enroll-info/gencert";
  return axios1.request({
    url,
    method: "post",
    data
  });
};

export const getMapData = data => {
  let url = `matchrecord/team-last-cp?matchid=${data.matchid}&trackid=${data.trackid}`;
  return api.request({
    url,
    method: "get",
    data
  });
};

export const morderDetail = params => {
  return axios2.request({
    url: "purchase/morder-detail",
    method: "get",
    params
  });
};

export const mpayOrder = data => {
  return axios2.request({
    url: "purchase/mpay-order",
    method: "post",
    data
  });
};

export const mbindUser = data => {
  return axios2.request({
    url: "purchase/mbind-user",
    method: "post",
    data
  });
};

export const wxLoginWap2 = data => {
  data.app = 11;
  return axios2.request({
    url: "user/wx-info",
    method: "post",
    data
  });
};

export const g100pscore = params => {
  let url = "showscore/g100pscore";
  return axios3.request({
    url,
    method: "get",
    params
  });
};

export const judgeLogin = data => {
  return axios.request({
    url: 'fivestar/judgelogin',
    method: "post",
    data
  });
};

export const judgeSearch = data => {
  return axios.request({
    url: 'fivestar/search',
    method: "post",
    data
  });
};

export const editJudgeInfo = data => {
  return axios.request({
    url: 'fivestar/editscore',
    method: "post",
    data
  });
};

export const judgeRank = data => {
  return axios.request({
    url: 'fivestar/scorelist',
    method: "post",
    data
  });
};

