import CryptoJS from "crypto-js";
import config from "@/config";
import router from "@/router";
import moment from "moment";
import { Dialog } from "vant";

export const getToken = () => {};
export const isJSON = str => {
  if (typeof str == "string") {
    try {
      if (str.indexOf("{") > -1) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else if (typeof str == "object") {
    return true;
  }
  return false;
};
export const checkMobile = str => {
  var re = /^1\d{10}$/;
  if (re.test(str)) {
    return true;
  } else {
    return false;
  }
};
export const formatTime = date => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
  return [year, month, day].map(formatNumber).join("-");
};
export const formatDate = (date, fmt) => {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  let o = {
    "Y+": date.getFullYear(),
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };

  // 遍历这个对象
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? formatNumber(str) : str
      );
    }
  }
  return fmt;
};
export const formatNumber = n => {
  n = n.toString();
  return n[1] ? n : "0" + n;
};
export const setLastRouter = to => {
  var { name, query, params } = to;
  var lastrouter = { name, query, params };
  return setStorage("loastroute", lastrouter, true);
};

export const getLastRouter = () => {
  return getStorage("loastroute");
};

export const isWeiXin = () => {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};
export const setStorage = (name, value, encode) => {
  if (encode) {
    if (isJSON(value)) value = appencode(JSON.stringify(value));
    else value = appencode(value);
  }
  window.localStorage.setItem(name, value);
  value = appdecode(value);
  if (isJSON(value)) {
    return JSON.parse(value);
  } else {
    return value;
  }
};

export const removeStorage = name => {
  var value = window.localStorage.removeItem(name);
  if (value) {
    return true;
  } else {
    return false;
  }
};

export const clearStorage = () => {
  var value = window.localStorage.clear();
  if (value) {
    return true;
  } else {
    return false;
  }
};

export const getStorage = name => {
  var value = window.localStorage.getItem(name);
  if (!value) return "";
  value = appdecode(value);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }

  // return JSON.parse(value);
};

export const appencode = info => {
  var mix = config.mixCode;
  var ciphertext = CryptoJS.AES.encrypt(info, mix).toString();
  return encodeURIComponent(ciphertext);
};

export const appdecode = ciphertext => {
  ciphertext = decodeURIComponent(ciphertext);
  var mix = config.mixCode;
  var bytes = CryptoJS.AES.decrypt(ciphertext, mix);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const goBack = url => {
  if (window.history.length > 1) {
    if (url == "/login" || url == "index") {
      router.replace({ name: "index" });
    } else {
      router.go(-1);
    }
    //
  } else {
    router.push({ name: "index" });
  }
};

export const islogin = () => {
  var userInfo = getStorage("userInfo");

  return userInfo != null && userInfo.urid != undefined && userInfo.urid;
};

export const checklogin = to => {
  if (!islogin()) {
    Dialog.confirm({
      title: "提示",
      message: "当前页面需要登录后才可访问,前往登录页面?",
      confirmButtonColor: "#F15B24"
    })
      .then(() => {
        // on confirm
        setLastRouter(to); //设置登陆后的调转
        router.replace({ name: "login" });
      })
      .catch(() => {
        // on cancel
        router.push({ name: "index" });
      });
  } else {
    return true;
  }
};

export const fromatMatchTime = e => {
  var currentt = moment().unix();
  if (currentt < e.reg_start_time) {
    return Object.assign(e, {
      btntext: "报名未开始",
      state: "未开始",
      fontcolor: "#FFFFFF",
      bgcolor: "rgba(128, 134, 149,.9)"
    });
  } else if (currentt > e.reg_start_time && currentt < e.reg_end_time) {
    return Object.assign(e, {
      regstate: 1,
      btntext: "立即报名",
      state: "报名中",
      fontcolor: "#FFFFFF",
      bgcolor: "rgba(25,190,107,.9)"
    });
  } else if (currentt > e.reg_end_time && currentt < e.start_time) {
    return Object.assign(e, {
      btntext: "报名已结束",
      state: "未开始",
      fontcolor: "#FFFFFF",
      bgcolor: "rgba(197, 200, 206,.9)"
    });
  } else if (currentt <= e.reg_end_time && currentt >= e.start_time) {
    return Object.assign(e, {
      btntext: "报名已结束",
      state: "进行中",
      fontcolor: "#FFFFFF",
      bgcolor: "rgba(25,190,107,.9)"
    });
  } else if (currentt >= e.end_time) {
    return Object.assign(e, {
      btntext: "报名已结束",
      state: "已结束",
      fontcolor: "#FFFFFF",
      bgcolor: "rgba(128, 134, 149,.9)"
    });
  } else {
    return Object.assign(e, {
      btntext: "报名已结束",
      state: "已结束",
      fontcolor: "#FFFFFF",
      bgcolor: "rgba(128, 134, 149,.9)"
    });
  }
};
