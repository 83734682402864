import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '主页',
      showloading: false,
      noLogin: true,
    },
  },{
    path: '/main',
    name: 'Main',
    component: () => import( /* webpackChunkName: "main" */ '../views/Main.vue'),
    meta: {
      title: '主页',
      showloading: false
    },
  },
  {
    path: '/question',
    name: 'question',
    component: () => import( /* webpackChunkName: "question" */ '../views/Question.vue'),
    meta: {
      title: '答题',
      showloading: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: '登录',
      showloading: false,
      noLogin: true,
    },
  },
  {
    path: '/redemlist',
    name: 'Redemlist',
    component: () => import( /* webpackChunkName: "login" */ '../views/Redemption.vue'),
    meta: {
      title: '兑换列表',
      showloading: false
    },
  },
  {
    path: '/goodslist',
    name: 'Goodslist',
    component: () => import( /* webpackChunkName: "login" */ '../views/Goodslist.vue'),
    meta: {
      title: '礼物列表',
      showloading: false
    },
  },
  {
    path: '/experiencelist',
    name: 'Experiencelist',
    component: () => import( /* webpackChunkName: "login" */ '../views/Experiencelist.vue'),
    meta: {
      title: '体验列表',
      showloading: false
    },
  },
  {
    path: '/reservcert',
    name: 'Reservcert',
    component: () => import( /* webpackChunkName: "login" */ '../views/Reservcert.vue'),
    meta: {
      title: '预约凭证',
      showloading: false
    },
  },
  {
    path: '/goodscert',
    name: 'Goodscert',
    component: () => import( /* webpackChunkName: "login" */ '../views/Goodscert.vue'),
    meta: {
      title: '纪念品',
      showloading: false
    },
  },
  {
    path: '/option',
    name: 'Option',
    component: () => import( /* webpackChunkName: "option" */ '../views/Option.vue'),
    meta: {
      title: '选择体验',
      showloading: false
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/Index.vue'),
    meta: {
      title: '上海体育博物馆预约核销系统',
      showloading: false
    },
  },
  {
    path: '/admin/record',
    name: 'Record',
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/Record.vue'),
    meta: {
      title: '核销记录',
      showloading: false
    },
  },
  {
    path: '/admin/queue',
    name: 'AdminQueue',
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/Queue.vue'),
    meta: {
      title: '排队叫号',
      showloading: false
    },
  },
  {
    path: '/admin/verify',
    name: 'AdminVerify',
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/Verify.vue'),
    meta: {
      title: '纪念品兑换核销',
      showloading: false
    },
  },
  {
    path: '/admin/dashboard',
    name: 'Dash',
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/dashboard.vue'),
    meta: {
      title: '数字大屏',
      showloading: false,
      noLogin: true,
    },
  },
  {
    path: '/queue',
    name: 'Queue',
    component: () => import( /* webpackChunkName: "queue" */ '../views/Queue.vue'),
    meta: {
      noLogin: true,
      title: '排队叫号',
      showloading: false
    },
  },
  {
    path: '/print',
    name: 'print',
    component: () => import( /* webpackChunkName: "queue" */ '../views/Print.vue'),
    meta: {
      title: '打印',
      showloading: false,
      noLogin: true,
    },
  },
	{
	  path: '/dashboard/deviceState',
	  name: 'device',
	  component: () => import( /* webpackChunkName: "deviceState" */ '../views/dashboard/deviceState.vue'),
	  meta: {
	    title: '设备状态',
	    showloading: false,
	    noLogin: true,
	  },
	},
	{
	  path: '/dashboard/dashboard',
	  name: 'databoard',
	  component: () => import( /* webpackChunkName: "databoard" */ '../views/dashboard/dashboard.vue'),
	  meta: {
	    title: '数据平台',
	    showloading: false,
	    noLogin: true,
	  },
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const LOGIN_PAGE_NAME = 'login';

router.beforeEach((to, from, next) => {
  if(typeof to.query.urid != 'undefined' && typeof to.query.token != 'undefined' && to.query.token && to.query.urid)
  {
    //自动登录，并且调回正常地址
    localStorage.setItem('urid', to.query.urid)
    localStorage.setItem('token', to.query.token)
    let userInfo= JSON.stringify({urid:to.query.urid,token:to.query.token})
    localStorage.setItem('userInfo', userInfo)
  }
  if (to.meta.noLogin !== true) {
    let user = store.getters.getUser
    if (user.urid > 0) {
      if (to.path.indexOf('/admin') !== -1) {
        if (to.path == '/admin') {
          next()
        } else {
          let role = store.getters.getRole
          if (role == 2) {
            next()
          } else {
            next({
              path: '/admin'
            })
          }
        }
      } else {
        next()
      }
    } else {
      if (to.name !== LOGIN_PAGE_NAME) {
        if (to.path.indexOf('/admin') !== -1) {
          next({
            name: LOGIN_PAGE_NAME,
            query: {
              admin: 1,
              logout: 1
            }
          })
        } else {
          next({
            name: LOGIN_PAGE_NAME
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

router.afterEach((to) => {

  if (to.meta.title) {
    document.title = to.meta.title;
  }
})

export default router
