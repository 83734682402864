import axios from "axios";
import store from "@/store";
import router from "@/router";
import Qs from "qs";
import { Dialog } from "vant";
import { removeStorage, goBack, getStorage } from "./utils";

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      timeout: 20000,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      paramsSerializer: function(params) {
        if (!params) params = {};
        var appinfo = store.getters.getAppInfo;

        let userinfo = getStorage("userInfo");
        if (userinfo) {
          let { urid, token } = userinfo;
          Object.assign(params, appinfo, { urid, token });
        } else {
          Object.assign(params, appinfo);
        }
        return Qs.stringify(params);
      },
      transformRequest: [
        function(data) {
          if (!data) {
            data = {};
          }
          var appinfo = store.getters.getAppInfo;
          let userinfo = getStorage("userInfo");
          if (userinfo) {
            let { urid, token } = userinfo;
            Object.assign(data, appinfo, { urid, token });
          } else {
            Object.assign(data, appinfo);
          }
          return Qs.stringify(data);
        }
      ]
    };
    return config;
  }
  destroy(url) {
    delete this.queue[url];
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        var appinfo = store.getters.getAppInfo;
        if (!appinfo) return Promise.reject("企业信息不存在");
        this.queue[url] = true;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      res => {
        this.destroy(url);
        const { data } = res;
        if (data.status == 200 || data.statusCode == 200) {
          //!!TODO move 定制
        } else if (data.status == 201 || data.message == "urid上传错误") {
          Dialog.confirm({
            title: "提示",
            message: "当前操作需要登录,立即登录?"
          })
            .then(() => {
              //同时登陆
              removeStorage("userInfo");
              router.replace({ name: "login" });
            })
            .catch(() => {
              goBack();
            });
        } else if (data.status == 205) {
          Dialog.confirm({
            title: "提示",
            message: data.message
          })
            .then(() => {
              // on close
            })
            .catch(() => {
              goBack();
            });
        } else {
          Dialog.alert({
            title: "提示",
            message: data.message
          }).then(() => {
            // on close
          });
        }

        return Promise.resolve(data);
        // return { data, status }
      },
      error => {
        let message = error.message;
        if (error.message.indexOf("timeout of") != -1) {
          message = "请求超时，请刷新页面再试";
        }
        Dialog.alert({
          title: "提示",
          message: message
        });
        // console.log(error.message);
        // console.log(error);
        this.destroy(url);
        return Promise.reject(error);
      }
    );
  }
  request(options) {
    if (options.method == "get" && !options.params) {
      options.params = { reget: 1 };
    }
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;
